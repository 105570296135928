import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import {VerifyProtectedRoute, ConnectWalletProtectedRoute} from './Utils/ProtectedRoutes';

import VerifyEmail from './Pages/VerifyEmail/VerifyEmail';
import VerifyOtp from './Pages/VerifyOtp/VerifyOtp';
import ConnectTrustWallet from './Pages/ConnectTrustWallet/ConnectTrustWallet';


const App = () => {
  return (
    <div className="App">
      
      <Router>
        <Routes>
          {/* Non-protected routes */}
          <Route 
            path='/*'
            element={
                <Routes>

                  {/* Managing the undefined routes */}
                  <Route path='*' element={<Navigate to='/' />} />
                
                  <Route element={<VerifyEmail/>} path='/' />

                  <Route element={<VerifyProtectedRoute/>}>
                    <Route element={<VerifyOtp/>} path='/verify' />
                  </Route>
                  
                </Routes>
            } 
          />

          {/* Protected Routes */}
          <Route
            path='/user/*'  //Routes will start with /user/then_the_routes_given_below
            element={
                <Routes>
                  <Route element={<ConnectWalletProtectedRoute/>}> {/* Protected */}

                    {/*Managing the undefined routes */}
                    <Route path='*' element={<Navigate to='/' />} />

                    <Route element={<ConnectTrustWallet/>} path='/connectWallet' />
                  </Route>
                </Routes>
            }
          />
          

        </Routes>
      </Router>
    </div>
  );
}

export default App;
