import './Navbar.css'
import Logo from '../../Assets/Logo.svg'

import MenuIcon from '@mui/icons-material/Menu';
const Navbar = () => {
    return(
        <div className='navbar-main' >
            <div className='navbar-main-left' >
                <div className='logo-container' ><img src={Logo} alt='logo' /></div>
                <h2>Trust Wallet</h2>
            </div>
            <div className='navbar-main-right' >
                <div className='hamburger-container' ><MenuIcon/></div>
                <div className='navbar-list-container' >
                    <h4>Assets</h4>
                    <h4>Staking</h4>
                    <h4>Browser Extension <span style={{backgroundColor:'#fff', color: '#2C64BE'}} >NEW</span></h4>
                    <h4>NFTs</h4>
                    <h4>DApp Browser</h4>
                    <h4>Language</h4>
                </div>
            </div>
        </div>
    )
}
export default Navbar;