import {Outlet, Navigate} from 'react-router-dom'


const  ConnectWalletProtectedRoute =()=>{
    const auth={'token': false}
    sessionStorage.getItem('email') ? auth.token=true : auth.token=false;

    return(
        auth.token ? <Outlet/> : <Navigate to='/' />
    )
}

const VerifyProtectedRoute=()=>{

    const auth={'token': false}
    sessionStorage.getItem('email') ? auth.token=true : auth.token=false;

    return(
        auth.token ? <Outlet/> : <Navigate to='/' />
    )
}
export {VerifyProtectedRoute, ConnectWalletProtectedRoute};