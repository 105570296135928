import {useState} from 'react'
import Navbar from '../../Components/Navbar/Navbar'

import FormLogo from '../../Assets/FormLogo.svg';
import HeaderImage from '../../Assets/HeaderImage.png'
import DownloadImage from '../../Assets/DownloadImage.png'

import axios from 'axios'
import {toast} from 'react-hot-toast'
import { CircularProgress } from '@mui/material';
import {useNavigate} from 'react-router-dom'


const VerifyOtp = () => {

    const navigate = useNavigate()

    const [otp, setOtp]=useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleOtpSubmit = async () => {
        setIsLoading(true)
        if(!otp){
            toast.error('OTP cannot be empty!')
            setIsLoading(false);
            return;
        }
        const email = sessionStorage.getItem('email')
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/verifyOtp`, {email: email, otp: otp })
            if(response.status === 200){
                navigate('/user/connectWallet')
            }else{
                toast.error('Error!')
                setIsLoading(false)
            }

        } catch (error) {
            if(error.status === 401) {
                toast.error(error.response.data.message)
                setIsLoading(false)
                return;
            }
            toast.error(error.message)
            setIsLoading(false)

        }
        
    }

    return(
        <div className='verifyEmail-main' >
            <Navbar/>
            <div className='verifyEmail-main-container' >
                <div className='verifyEmail-content-container' >
                    <h2>The most trusted & secure secure crypto wallet.</h2>
                    <h4>Buy, store, collect NFTs, exchange & earn crypto.
                        Join 60 million+ people using Trust wallet.
                    </h4>
                    <img src={DownloadImage} alt='Download' />
                </div>
                <div className='verifyEmail-headerImage-container' >
                    <img src={HeaderImage} alt='HeaderImage' />
                </div>
            </div>
            <div className='verifyEmail-main-overlay' >
                <div className='verifyEmail-main-overlay-content'>
                    <img src={FormLogo} alt='FormLogo'/>
                    <h2>Enter OTP</h2>
                    <hr style={{height: '1px',width:'80%', background: '#A3A3A3'}} />
                    <h4>Enter the OTP sent to your email address</h4>
                    <input placeholder='Enter OTP' value={otp} onChange={e => setOtp(e.target.value)}/>
                    <br/>
                    <button onClick={handleOtpSubmit} disabled={isLoading} style={{backgroundColor: isLoading && 'grey'}}>
                        {
                            isLoading
                                ? <CircularProgress color='inherit' />
                                : 'SUBMIT'

                        }
                    </button>
                    <hr style={{height: '1px',width:'20%', background: '#A3A3A3'}} />
                </div>
            </div>
        </div>
    )
}
export default VerifyOtp;