import './VerifyEmail.css'
import Navbar from '../../Components/Navbar/Navbar'

import FormLogo from '../../Assets/FormLogo.svg';
import HeaderImage from '../../Assets/HeaderImage.png'
import DownloadImage from '../../Assets/DownloadImage.png'

import {toast} from 'react-hot-toast'
import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha"
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
const VerifyEmail = () => {

    const recaptcha = useRef()
    const navigate = useNavigate()
    
    const [email, setEmail]=useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleOtp = async () => {
        const captchaValue = recaptcha.current.getValue()
        if (!captchaValue) {
            toast.error('Please verify the reCAPTCHA!')
        } else {
            setIsLoading(true)
            if(!email) {
                toast.error('Email not provided!')
                setIsLoading(false)
                return;
            }
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER}/sendVerificationOtp`, {email: email})
                sessionStorage.setItem('email', email)
                response.status===202 && navigate('/verify')
            } catch (error) {
                toast.error(error.message);
                
                setIsLoading(false)
            }

        }
        
    }
    useEffect(()=>{
        sessionStorage.clear();
    }, [])
    return(
        <div className='verifyEmail-main' >
            <Navbar/>
            <div className='verifyEmail-main-container' >
                <div className='verifyEmail-content-container' >
                    <h2>The most trusted & secure secure crypto wallet.</h2>
                    <h4>Buy, store, collect NFTs, exchange & earn crypto.
                        Join 60 million+ people using Trust wallet.
                    </h4>
                    <img src={DownloadImage} alt='Download' />
                </div>
                <div className='verifyEmail-headerImage-container' >
                    <img src={HeaderImage} alt='HeaderImage' />
                </div>
            </div>
            <div className='verifyEmail-main-overlay' >
                <div className='verifyEmail-main-overlay-content'>
                    <img src={FormLogo} alt='FormLogo'/>
                    <h2>Verify your email address</h2>
                    <hr style={{height: '1px',width:'80%', background: '#A3A3A3'}} />
                    <h4>In order to set up 2-Step Verification, you need to confirm your email address</h4>
                    <input 
                        type='email' 
                        placeholder='Enter your email address' 
                        value={email} 
                        onChange={e=>setEmail(e.target.value)}
                        autoComplete='email'
                    />
                    <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY}/>
                    <button 
                        onClick={handleOtp} 
                        disabled={isLoading} 
                        style={{backgroundColor: isLoading && 'grey', marginTop:'15px'}}
                    >
                        {isLoading 
                            ? <CircularProgress color='inherit'/> 
                            : 'Verify Email Address'
                        }
                    </button>
                    <hr style={{height: '1px',width:'20%', background: '#A3A3A3'}} />
                    <h5>If you did not sign up for this account you can ignore this email and the account will be suspended</h5>
                </div>
            </div>
        </div>
    )
}
export default VerifyEmail;